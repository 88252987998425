import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Collapse,
  IconButton,
  Link,
  List,
  ListItem,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BorderCtaButton from "../../../../../components/button/border-cta-button.component";
import CtaButton from "../../../../../components/button/cta-button.component";
import TableHeader from "../../../../../components/tables/table-header.component";
import Spacer from "../../../../../components/utils/spacer.component";
import { fitnessClassSessionSelector } from "../../../../../services/merchant/fitness-class/sessions/session.slice.service";
import SessionCancelConfirmationModal from "./session-cancel-confirmation-modal.component";
import SessionPublishConfirmationModal from "./session-publish-confirmation-modal.component";

const TableTopContainer = styled(Box)(({ theme }) => ({
  padding: "6px 16px",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  borderBottom: `1px solid ${theme.palette.colors.ui.tableBorder}`,
  alignItems: "flex-end",
}));

const TABLE_CELL_WIDTH = 150;
function Row({ record, handleUpdateSessionStatus }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [collapse, setCollapse] = useState(false);
  const { updateSessionStatusObj } = useSelector(fitnessClassSessionSelector);
  const [showPublishConfirmationModal, setShowPublishConfirmationModal] = useState(false);
  const [showCancelConfirmationModal, setShowCancelConfirmationModal] = useState(false);

  return (
    <>
      <SessionPublishConfirmationModal
        item={record}
        showModal={showPublishConfirmationModal}
        setShowModal={setShowPublishConfirmationModal}
        handleUpdateSessionStatus={handleUpdateSessionStatus}
      />
      <SessionCancelConfirmationModal
        item={record}
        showModal={showCancelConfirmationModal}
        setShowModal={setShowCancelConfirmationModal}
        handleUpdateSessionStatus={handleUpdateSessionStatus}
      />
      <TableRow>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <Typography variant="body2" noWrap={true} sx={{ width: TABLE_CELL_WIDTH }}>
            {record.class.name}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <Typography variant="body2" sx={{ width: TABLE_CELL_WIDTH }}>
            {record.startAt} - {record.endAt}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <Typography variant="body2" noWrap={true} sx={{ width: TABLE_CELL_WIDTH }}>
            RM {record.price}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <Typography variant="body2" noWrap={true} sx={{ width: TABLE_CELL_WIDTH }}>
            {record.currentCapacity} / {record.maxCapacity}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <Typography
            variant="body2"
            noWrap={true}
            sx={{ width: TABLE_CELL_WIDTH, textTransform: "capitalize" }}
          >
            {record.status}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: 150 }}>
          <IconButton
            disabled={record.status !== "draft"}
            onClick={() => navigate(`/merchants/fitness_class/sessions/${record.id}/edit`)}
          >
            <EditIcon />
          </IconButton>
        </TableCell>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          {record.allowPublish && record.status === "draft" && (
            <>
              <CtaButton
                onClickButton={() => setShowPublishConfirmationModal(true)}
                disabled={updateSessionStatusObj.status === "pending"}
              >
                <Typography variant="body2" noWrap={true}>
                  Publish
                </Typography>
              </CtaButton>
              <Spacer />
            </>
          )}

          {record.status !== "cancelled" && (
            <BorderCtaButton
              onClickButton={() => setShowCancelConfirmationModal(true)}
              disabled={updateSessionStatusObj.status === "pending"}
            >
              <Typography
                variant="body2"
                noWrap={true}
                sx={{ color: theme.palette.colors.brand.primary }}
              >
                Cancel
              </Typography>
            </BorderCtaButton>
          )}
        </TableCell>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setCollapse(!collapse)}>
            {collapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={collapse} unmountOnExit timeout="auto">
            <TableTopContainer>
              <Typography sx={{ fontWeight: "bold" }}>Merchant Business Name</Typography>
            </TableTopContainer>
            <List sx={{ listStyleType: "disc", pl: 4 }}>
              <Link color="inherit" href={`/merchants/edit?merchantId=${record.merchant.id}`}>
                <Typography sx={{ textTransform: "capitalize", fontWeight: "bold" }}>
                  {record.merchant.businessName}
                </Typography>
              </Link>
            </List>
            <Spacer size="m" />
            <TableTopContainer>
              <Typography sx={{ fontWeight: "bold" }}>Business</Typography>
            </TableTopContainer>
            <List sx={{ listStyleType: "disc", pl: 4 }}>
              <ListItem sx={{ display: "list-item" }} disablePadding>
                {record.business.name}
              </ListItem>
            </List>
            <Spacer size="m" />
            <TableTopContainer>
              <Typography sx={{ fontWeight: "bold" }}>Category</Typography>
            </TableTopContainer>
            <List sx={{ listStyleType: "disc", pl: 4 }}>
              <Typography sx={{ textTransform: "capitalize", fontWeight: "bold" }}>
                {record.category.label}
              </Typography>
            </List>

            <Spacer size="m" />
            <TableTopContainer>
              <Typography sx={{ fontWeight: "bold" }}>Staff List</Typography>
            </TableTopContainer>
            <Table size="small">
              <TableHeader
                headerCells={["Staff", "Name", "Phone", "Email", "Gender", "Date of birth"]}
                nonSortableColumns={["Staff", "Name", "Phone", "Email", "Gender", "Date of birth"]}
              />
              <TableBody>
                {record.staff ? (
                  <TableRow key={record.staff.id}>
                    <TableCell sx={{ width: TABLE_CELL_WIDTH * 1.6 }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={record.staff.image_path}
                          alt="staff"
                          style={{ width: 60, borderRadius: theme.shape.borderRadius[1] }}
                        />
                      </Box>
                    </TableCell>
                    <TableCell width="200px">
                      <Typography variant="body2" noWrap>
                        {record.staff.name}
                      </Typography>
                    </TableCell>
                    <TableCell width="200px">
                      <Typography variant="body2">{record.staff.phone}</Typography>
                    </TableCell>
                    <TableCell width="200px">
                      <Typography variant="body2">{record.staff.email}</Typography>
                    </TableCell>
                    <TableCell width="200px">
                      <Typography variant="body2">{record.staff.gender}</Typography>
                    </TableCell>
                    <TableCell width="200px">
                      <Typography variant="body2">{record.staff.dob}</Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell width="200px">-</TableCell>
                    <TableCell width="200px">-</TableCell>
                    <TableCell width="200px">-</TableCell>
                    <TableCell width="200px">-</TableCell>
                    <TableCell width="200px">-</TableCell>
                    <TableCell width="200px">-</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Spacer size="xl" />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  handleUpdateSessionStatus: PropTypes.func.isRequired,
  record: PropTypes.shape({
    id: PropTypes.number.isRequired,
    merchant: PropTypes.shape({
      id: PropTypes.number.isRequired,
      businessName: PropTypes.string.isRequired,
    }),
    business: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    class: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    category: PropTypes.shape({
      label: PropTypes.string.isRequired,
    }),
    startAt: PropTypes.string.isRequired,
    endAt: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    currentCapacity: PropTypes.number.isRequired,
    maxCapacity: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    allowPublish: PropTypes.bool.isRequired,
    staff: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      gender: PropTypes.string.isRequired,
      dob: PropTypes.string.isRequired,
      image_path: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default Row;
