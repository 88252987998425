import {
  Box,
  Breadcrumbs,
  Grid,
  Link,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { differenceInDays, format } from "date-fns";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Form from "../../../../../components/forms/form.component";

import FormSubmitButton from "../../../../../components/forms/form-submit-button.component";
import { SnackbarContext } from "../../../../../components/notification/snackbar.context";
import PaddedView from "../../../../../components/utils/padded-view.component";
import Spacer from "../../../../../components/utils/spacer.component";
import {
  fitnessClassSelector,
  getClassDetail,
} from "../../../../../services/merchant/fitness-class/class-slice.service";
import {
  addRecurrentSessions,
  addSession,
  fitnessClassSessionSelector,
} from "../../../../../services/merchant/fitness-class/sessions/session.slice.service";
import OneOffCreateSessionForm from "../components/one-off-create-session-form.component";
import RecurrentCreateSessionForm from "../components/recurrent-create-session-form.component";

const recurrentValidationSchema = Yup.object().shape({
  classId: Yup.number().required(),
  startDate: Yup.date().required().label("Start date").typeError("Invalid date"),
  endDate: Yup.date().required().label("End date").typeError("Invalid date"),
  startTime: Yup.string().required().label("Start time"),
  endTime: Yup.string().required().label("End time"),
  maxCapacity: Yup.number().required().label("Capacity"),
  price: Yup.number().required().typeError("Price is required"),
  staffId: Yup.mixed().required().label("Staff(s)"),
});

const oneOffValidationSchema = Yup.object().shape({
  classId: Yup.number().required(),
  startAt: Yup.date().required().label("Start At"),
  endAt: Yup.date()
    .min(Yup.ref("startAt"), "End at can't be before start date")
    .label("End At")
    .required(),
  price: Yup.number().required().typeError("Price is required"),
  maxCapacity: Yup.number().required().label("Max Capacity"),
  staffId: Yup.mixed().required().label("Staff"),
});

export default function SessionNewScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addSessionObj, addRecurrentSessionsObj } = useSelector(fitnessClassSessionSelector);
  const { getClassDetailObj } = useSelector(fitnessClassSelector);
  const createSnackBar = useContext(SnackbarContext);
  const formRef = useRef();
  const [createType, setCreateType] = useState("one-off");

  useEffect(() => {
    if (formRef.current?.values.classId) {
      dispatch(getClassDetail({ classId: formRef.current.values.classId })).then(
        ({ meta, payload }) => {
          if (meta.requestStatus === "fulfilled") {
            formRef.current.setFieldValue("merchantId", payload.data.merchant.id);
          }
        },
      );
    }
  }, [formRef.current?.values.classId]);

  const onHandleSubmit = (values) => {
    let payloadParams = {};
    const amountCents = values.price * 100;

    if (createType === "one-off") {
      const startAt = `${format(new Date(values.startAt), "yyyy-MM-dd HH:mm:00")}`;
      const endAt = `${format(new Date(values.endAt), "yyyy-MM-dd HH:mm:00")}`;
      if (
        new Date(values.startAt) < new Date() &&
        new Date(values.startAt).getTime() < new Date().getTime()
      ) {
        formRef.current.setFieldError(
          "startAt",
          "Start Date Time cannot earlier than current date time",
        );
        return;
      }

      payloadParams = {
        staffId: values.staffId.id,
        amountCents,
        startAt,
        endAt,
        classId: values.classId,
        maxCapacity: values.maxCapacity,
      };
    } else if (createType === "recurrent") {
      const startTimeFormatted = format(new Date(values.startTime), "HH:mm:00");
      const endTimeFormatted = format(new Date(values.endTime), "HH:mm:00");
      const startDateFormatted = format(new Date(values.startDate), "yyyy-MM-dd");
      const endDateFormatted = format(new Date(values.endDate), "yyyy-MM-dd");

      const startAt = `${format(new Date(values.startDate), "yyyy-MM-dd")} ${format(
        new Date(values.startTime),
        "HH:mm:00",
      )}`;

      if (format(new Date(), "yyyy-MM-dd") > startDateFormatted) {
        formRef.current.setFieldError("startDate", "Start date needs to be today or later");
        return;
      }

      if (endDateFormatted < startDateFormatted) {
        formRef.current.setFieldError(
          "endDate",
          "End date needs to be later or same day as start date",
        );
        return;
      }

      if (format(new Date(), "yyyy-MM-dd HH:mm:00") > startAt && values.endType === "endDate") {
        formRef.current.setFieldError("startTime", "Start time has passed");
        return;
      }

      if (startTimeFormatted >= endTimeFormatted) {
        formRef.current.setFieldError("endTime", "End time cannt be before start time");
        return;
      }

      if (values.endType === "occurenceNumber" && values.occurenceNumber === null) {
        formRef.current.setFieldError("occurenceNumber", "Missing occurence number");
        return;
      }

      if (values.occurenceType === "weekly" && values.weeklyRepeat.length === 0) {
        formRef.current.setFieldError("weeklyRepeat", "Please select at least one");
        return;
      }

      if (values.occurenceType === "weekly" && values.weeklyRepeat.length > 0) {
        switch (values.weeklyRepeat.length) {
          case 1:
            if (values.occurenceNumber > 12) {
              formRef.current.setFieldError("occurenceNumber", "Maximum occurences allowed is 12");
              return;
            }
            break;
          case 2:
            if (values.occurenceNumber > 24) {
              formRef.current.setFieldError("occurenceNumber", "Maximum occurences allowed is 24");
              return;
            }
            break;
          case 3:
            if (values.occurenceNumber > 36) {
              formRef.current.setFieldError("occurenceNumber", "Maximum occurences allowed is 36");
              return;
            }
            break;
          case 4:
            if (values.occurenceNumber > 48) {
              formRef.current.setFieldError("occurenceNumber", "Maximum occurences allowed is 48");
              return;
            }
            break;
          case 5:
            if (values.occurenceNumber > 60) {
              formRef.current.setFieldError("occurenceNumber", "Maximum occurences allowed is 60");
              return;
            }
            break;
          case 6:
            if (values.occurenceNumber > 72) {
              formRef.current.setFieldError("occurenceNumber", "Maximum occurences allowed is 72");
              return;
            }
            break;
          case 7:
            if (values.occurenceNumber > 84) {
              formRef.current.setFieldError("occurenceNumber", "Maximum occurences allowed is 84");
              return;
            }
            break;
          default:
            break;
        }
      }

      if (
        values.occurenceType === "weekly" &&
        values.occurenceNumber === null &&
        differenceInDays(new Date(values.endDate), new Date(values.startDate)) < 7
      ) {
        formRef.current.setFieldError(
          "endDate",
          "Please select end date at least 7 days from start date",
        );
        return;
      }

      payloadParams = {
        staffId: values.staffId.id,
        amountCents,
        classId: values.classId,
        maxCapacity: values.maxCapacity,
        startDate: startDateFormatted,
        startTime: startTimeFormatted,
        endTime: endTimeFormatted,
        occurenceType: values.occurenceType,
        weeklyRepeat: values.occurenceType === "weekly" ? values.weeklyRepeat : null,
        occurenceNumber:
          values.endType === "occurenceNumber" ? Number(values.occurenceNumber) : null,
        endDate: values.endType === "endDate" ? endDateFormatted : null,
      };
    }

    if (createType === "one-off") {
      dispatch(addSession(payloadParams)).then(({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          navigate("/merchants/fitness_class/sessions/list");
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      });
    } else if (createType === "recurrent") {
      dispatch(addRecurrentSessions(payloadParams)).then(({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          navigate("/merchants/fitness_class/sessions/list");
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      });
    }
  };

  return (
    <Box>
      <PaddedView>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">New Session</Typography>
          </Grid>
          <Grid item sx={{ alignSelf: "center" }}>
            <Stack spacing={2} sx={{ paddingRight: 1 }}>
              <Breadcrumbs separator=">" aria-label="breadcrumb">
                <Link underline="hover" key="1" color="inherit" href="/">
                  <Typography variant="body1">Dashboard</Typography>
                </Link>
                <Link
                  underline="hover"
                  key="2"
                  color="inherit"
                  href="/merchants/fitness_class/sessions/list"
                >
                  <Typography variant="body1">Session List</Typography>
                </Link>
                <Typography variant="body1" sx={{ textDecoration: "underline" }}>
                  New Session
                </Typography>
              </Breadcrumbs>
            </Stack>
          </Grid>
        </Grid>

        <Spacer size="xl" />

        <Form
          initialValues={{
            classId: null,
            selectedClass: null,
            occurenceType: "daily",
            endType: "endDate",
            startAt: new Date(),
            endAt: new Date(),
            startDate: new Date(),
            endDate: new Date(),
            startTime: new Date(),
            endTime: new Date(),
            maxCapacity: 0,
            price: "",
            staffId: [],
            occurenceNumber: "",
            weeklyRepeat: [],
          }}
          innerRef={formRef}
          onSubmit={onHandleSubmit}
          validationSchema={
            createType === "one-off" ? oneOffValidationSchema : recurrentValidationSchema
          }
        >
          {getClassDetailObj.status === "succeeded" && (
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Merchant: {getClassDetailObj.data.merchant.businessName}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Business: {getClassDetailObj.data.business.name}
                </Typography>
              </Grid>
            </Grid>
          )}

          <Grid item xs={isMobile ? 12 : 4}>
            <Spacer />
            <ToggleButtonGroup
              value={createType}
              exclusive
              onChange={(e) => {
                setCreateType(e.target.value);
              }}
            >
              <ToggleButton value="one-off" sx={{ whiteSpace: "nowrap" }}>
                One-Off
              </ToggleButton>
              <ToggleButton value="recurrent" sx={{ whiteSpace: "nowrap" }}>
                Recurrent
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>

          <Spacer size="xl" />
          {createType === "one-off" ? <OneOffCreateSessionForm /> : <RecurrentCreateSessionForm />}
          <Spacer />
          <Grid item xs={12} sm={12}>
            <Grid container justifyContent="flex-end">
              <Grid item sm={2} xs={12}>
                <FormSubmitButton
                  type="submit"
                  disabled={
                    addSessionObj.status === "pending" ||
                    addRecurrentSessionsObj.status === "pending"
                  }
                >
                  <Typography>Create</Typography>
                </FormSubmitButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </PaddedView>
    </Box>
  );
}
