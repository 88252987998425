import React, { useState } from "react";
import * as Yup from "yup";
import { Box, Typography, Grid, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import PaddedView from "../../../../components/utils/padded-view.component";
import Form from "../../../../components/forms/form.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import FormFieldText from "../../../../components/forms/form-field-text.component";
import LoadingSpinner from "../../../../components/utils/backdrop-loading.component";
import CategoryImageUpload from "../../components/category-image-upload.component";
import CustomizedSnackbars from "../../../../components/notification/snackbar.component";
import Spacer from "../../../../components/utils/spacer.component";
import {
  updateCategory,
  updateCategoryImage,
} from "../../../../services/category/sport-category-slice.service";
import BorderButton from "../../../announcement/components/border-button.component";
import OutlinedSelectTextField from "../../../../components/forms/outlined-select-text-field.component";

const validationSchema = Yup.object().shape({
  label: Yup.string().required().min(2).label("Title"),
  image: Yup.string().required().label("Image"),
});

function SportCategoryEditScreen() {
  const location = useLocation();
  const categoryId = location.state.id;

  const theme = useTheme();
  const dispatch = useDispatch();
  const [filePreview, setFilePreview] = useState(location.state.imagePath);
  const [isLoading, setIsLoading] = useState(false);
  const [snackBarType, setSnackBarType] = useState("error");
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [onImageChange, setOnImageChange] = useState(false);
  const navigate = useNavigate();

  const showErrorSnackBar = (message) => {
    setSnackBarMsg(message);
    setSnackBarType("error");
    setIsShowSnackBar(true);
  };

  const showSuccessSnackBar = (message) => {
    setSnackBarMsg(message);
    setSnackBarType("success");
    setIsShowSnackBar(true);
  };

  const handleSubmit = (values) => {
    setIsLoading(true);
    dispatch(updateCategory(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        showSuccessSnackBar(payload.message);
      }
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
      setIsLoading(false);
    });

    if (onImageChange) {
      dispatch(updateCategoryImage(values)).then(({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          setOnImageChange(false);
          showSuccessSnackBar(payload.message);
        }
        if (meta.requestStatus === "rejected") {
          showErrorSnackBar(error.message);
        }
        setIsLoading(false);
      });
    }
  };

  const renderEditCategory = () => (
    <Form
      validationSchema={validationSchema}
      initialValues={{
        categoryId,
        label: location.state.label,
        active: location.state.active,
        image: location.state.imagePath,
      }}
      onSubmit={handleSubmit}
    >
      <Grid container spacing={3} paddingTop={2}>
        <Grid item xs={12} sm={12}>
          <Grid
            container
            sx={{
              flexDirection: "row",
              alignItems: "flex-start",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", flex: 5 }}>
              <Box sx={{ width: "100%" }}>
                <FormFieldText
                  width="100%"
                  name="label"
                  placeholder="Write the label here"
                  label="Label"
                />
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Grid item xs={12} sm={3}>
            <OutlinedSelectTextField
              name="active"
              label="Status"
              selected={location.state.active}
              selections={[
                { type: "Inactive", value: false },
                { type: "Active", value: true },
              ]}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography>Category Icon</Typography>
          <Spacer />
          <CategoryImageUpload
            name="image"
            filePreview={filePreview}
            setFilePreview={setFilePreview}
            onImageChange={onImageChange}
            setOnImageChange={setOnImageChange}
          />
        </Grid>

        <Grid item xs={12} sm={10}>
          <Grid container spacing={3}>
            <Grid item sm={2} xs={12}>
              <FormSubmitButton type="submit">
                <Typography>Save</Typography>
              </FormSubmitButton>
            </Grid>
            <Spacer size="s" position="left" />
            <Grid item sm={2} xs={12}>
              <BorderButton onClickButton={() => navigate("/category/sport/list")}>
                <Typography sx={{ color: theme.palette.colors.brand.primary }}>Cancel</Typography>
              </BorderButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );

  return (
    <Box>
      <CustomizedSnackbars
        isShow={isShowSnackBar}
        setShowSnackbar={setIsShowSnackBar}
        message={snackBarMsg}
        type={snackBarType}
      />
      <LoadingSpinner isLoading={isLoading} />
      <PaddedView>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">Edit Category</Typography>
          </Grid>

          {renderEditCategory()}
        </Grid>
      </PaddedView>
    </Box>
  );
}

export default SportCategoryEditScreen;
