import { FormHelperText, Popper, styled, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { useDispatch, useSelector } from "react-redux";
import {
  getStaffAutoComplete,
  merchantStaffSelector,
} from "../../../../../services/merchant/staff/staff-slice.service";

const StyledTextField = styled(TextField)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[1],
  borderTopRightRadius: theme.shape.borderRadius[1],
  borderBottomLeftRadius: theme.shape.borderRadius[1],
  borderBottomRightRadius: theme.shape.borderRadius[1],
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderTopLeftRadius: theme.shape.borderRadius[1],
    borderTopRightRadius: theme.shape.borderRadius[1],
    borderBottomLeftRadius: theme.shape.borderRadius[1],
    borderBottomRightRadius: theme.shape.borderRadius[1],
    backgroundColor: theme.palette.colors.text.white,
  },
}));

function CustomPopper(props) {
  return <Popper {...props} placement="bottom" />;
}

export default function StaffListAutoComplete({ name, placeholder, disabled, forEdit }) {
  const dispatch = useDispatch();
  const { getStaffAutoCompleteObj } = useSelector(merchantStaffSelector);
  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext();
  const showError = touched[name] && typeof errors[name] === "string";
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [options, setOptions] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [loadingMoreResults, setLoadingMoreResults] = useState(false);

  const loadMoreResults = () => {
    let newPage = page;

    if (newPage < totalPage) {
      setLoadingMoreResults(true);
      const newOptions = options;
      setOptions([...newOptions, { id: "loading", option: "loading" }]);
      newPage += 1;
      setPage(newPage);
      dispatch(
        getStaffAutoComplete({
          merchantId: values.merchantId,
          q: keyword,
          page: newPage,
        }),
      ).then(({ meta, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          setLoadingMoreResults(false);
          setOptions([...newOptions, ...payload.data.items]);
        }
      });
    }
  };

  const onSearchKeywordChange = (q) => {
    setPage(1);
    setOptions([]);

    dispatch(getStaffAutoComplete({ merchantId: values.merchantId, q, page: 1 })).then(
      ({ meta, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          setTotalPage(payload.data.pagination.totalPages);
          setOptions(payload.data.items);
        }
      },
    );
  };

  const listOnChange = (event, value) => {
    setFieldValue(name, value);
    onSearchKeywordChange("");
  };

  const getLoadingText = () => {
    if (values.merchantId) {
      if (getStaffAutoCompleteObj.status === "succeeded") {
        return "No options";
      }
      if (getStaffAutoCompleteObj.status === "pending") {
        return "Loading...";
      }
    }

    return "Please select a merchant first";
  };

  useEffect(() => {
    if (!forEdit) {
      setOptions([]);
      setFieldValue(name, []);
    }
    if (values.merchantId) {
      onSearchKeywordChange("");
    }
  }, [values.merchantId]);

  return (
    <>
      <Autocomplete
        filterOptions={(x) => x}
        loading={options.length === 0}
        loadingText={getLoadingText()}
        ListboxProps={{
          role: "list-box",
          onScroll: (event) => {
            const listboxNode = event.currentTarget;
            if (
              !loadingMoreResults &&
              listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight
            ) {
              loadMoreResults();
            }
          },
        }}
        PopperComponent={CustomPopper}
        disabled={disabled}
        freeSolo
        onChange={listOnChange}
        options={options}
        getOptionDisabled={(option) => option.option === "loading"}
        filterSelectedOptions
        value={values[name]}
        disableCloseOnSelect
        getOptionLabel={(option) => option.option || ""}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        sx={{ width: "100%" }}
        renderInput={(params) => (
          <DebounceInput
            {...params}
            debounceTimeout={500}
            error={showError}
            onBlur={() => {
              setFieldTouched(name);
            }}
            element={StyledTextField}
            placeholder={placeholder}
            variant="outlined"
            onChange={(e) => {
              setKeyword(e.target.value);
              onSearchKeywordChange(e.target.value);
            }}
          />
        )}
      />
      {showError && (
        <FormHelperText error sx={{ margin: "3px 14px 0px" }}>
          {errors[name]}
        </FormHelperText>
      )}
    </>
  );
}

StaffListAutoComplete.defaultProps = {
  disabled: false,
};

StaffListAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  forEdit: PropTypes.bool.isRequired,
};
