import { Box, Breadcrumbs, Grid, Link, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import moment from "moment";
import Form from "../../../../../components/forms/form.component";

import FormFieldNumber from "../../../../../components/forms/form-field-number.component";
import FormPickerDateTime from "../../../../../components/forms/form-picker-date-time.component";
import FormPrice from "../../../../../components/forms/form-price.component";
import FormSubmitButton from "../../../../../components/forms/form-submit-button.component";
import { SnackbarContext } from "../../../../../components/notification/snackbar.context";
import PaddedView from "../../../../../components/utils/padded-view.component";
import Spacer from "../../../../../components/utils/spacer.component";
import {
  fitnessClassSessionSelector,
  getSessionDetail,
  updateSession,
} from "../../../../../services/merchant/fitness-class/sessions/session.slice.service";
import StaffListAutoComplete from "../components/staff-list-autocomplete.component";

const validationSchema = Yup.object().shape({
  sessionId: Yup.number().required(),
  startAt: Yup.date().required().label("Start At"),
  endAt: Yup.date()
    .min(Yup.ref("startAt"), "End at can't be before start date")
    .label("End At")
    .required(),
  amountCents: Yup.number().required().label("Price"),
  maxCapacity: Yup.number().required().label("Max Capacity"),
  staffId: Yup.mixed().required().label("Staff"),
});

export default function SessionEditScreen() {
  const dispatch = useDispatch();
  const params = useParams();
  const { getSessionDetailObj, updateSessionObj } = useSelector(fitnessClassSessionSelector);
  const createSnackBar = useContext(SnackbarContext);

  useEffect(() => {
    dispatch(getSessionDetail({ sessionId: params.session_id }));
  }, []);

  const onHandleSubmit = (values) => {
    dispatch(
      updateSession({
        ...values,
        startAt: moment(values.startAt).format("YYYY-MM-DD HH:mm:ss"),
        endAt: moment(values.endAt).format("YYYY-MM-DD HH:mm:ss"),
        amountCents: parseFloat(values.amountCents * 100),
        staffId: values.staffId.id,
      }),
    ).then(({ meta, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({ type: "success", message: payload.message });
      }
    });
  };

  return (
    <Box>
      <PaddedView>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Session Edit</Typography>
          </Grid>
          <Grid item sx={{ alignSelf: "center" }}>
            <Stack spacing={2} sx={{ paddingRight: 1 }}>
              <Breadcrumbs separator=">" aria-label="breadcrumb">
                <Link underline="hover" key="1" color="inherit" href="/">
                  <Typography variant="body1">Dashboard</Typography>
                </Link>
                <Link
                  underline="hover"
                  key="2"
                  color="inherit"
                  href="/merchants/fitness_class/sessions/list"
                >
                  <Typography variant="body1">Session List</Typography>
                </Link>
                <Typography variant="body1" sx={{ textDecoration: "underline" }}>
                  Session Edit
                </Typography>
              </Breadcrumbs>
            </Stack>
          </Grid>
        </Grid>

        <Spacer size="xl" />
        {getSessionDetailObj.status === "succeeded" && (
          <Form
            initialValues={{
              sessionId: getSessionDetailObj.data.id,
              merchantId: getSessionDetailObj.data.merchant.id,
              startAt: getSessionDetailObj.data.startAt,
              endAt: getSessionDetailObj.data.endAt,
              amountCents: getSessionDetailObj.data.price,
              maxCapacity: getSessionDetailObj.data.maxCapacity,
              staffId: {
                ...getSessionDetailObj.data.staff,
                option: getSessionDetailObj.data.staff.email,
              },
            }}
            onSubmit={onHandleSubmit}
            validationSchema={validationSchema}
          >
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Merchant: {getSessionDetailObj.data.merchant.businessName}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Business: {getSessionDetailObj.data.business.name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Class: {getSessionDetailObj.data.class.name}</Typography>
              </Grid>
            </Grid>
            <Spacer size="xl" />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={5}>
                <FormPickerDateTime name="startAt" label="*Start At" />
              </Grid>
              <Grid item xs={12} sm={5}>
                <FormPickerDateTime name="endAt" label="*End At" />
              </Grid>
              <Grid item xs={12} sm={5}>
                <FormFieldNumber
                  name="maxCapacity"
                  placeholder="Enter maximum capacity"
                  label="Capacity"
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <FormPrice name="amountCents" placeholder="Enter price" label="Price" />
              </Grid>
              <Grid item xs={12}>
                <StaffListAutoComplete
                  disabled={false}
                  name="staffId"
                  placeholder="Staff"
                  forEdit={true}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Grid container justifyContent="flex-end">
                  <Grid item sm={2} xs={12}>
                    <FormSubmitButton
                      type="submit"
                      disabled={updateSessionObj.status === "pending"}
                    >
                      <Typography>Submit</Typography>
                    </FormSubmitButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </PaddedView>
    </Box>
  );
}
